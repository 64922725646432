<template>
  <div>
    <div class="my-4 max-width flex justify-end">
      <d-input type="text" placeholder="Search" v-model="search" />
      <d-btn @click="_fetch">Search</d-btn>
    </div>

    <div class="mt-4 max-width flex justify-end">
      <d-paging :total="totalPage" v-model="page" size="xs" />
    </div>

    <div class="overflow-x-auto">
      <d-table :headers="headers" :items="news" compact class="mt-4" />
    </div>

    <div class="mt-4 max-width flex justify-end">
      <d-paging :total="totalPage" v-model="page" size="xs" />
    </div>
  </div>
</template>

<script>
import headersGenerator from './headers.js'

export default {
  name: 'news',

  meta: {
    title: 'News',
  },

  data: () => ({
    news: [],
    page: 1,
    search: '',
    totalPage: 0,
  }),

  created() {
    this._fetch()
  },

  computed: {
    headers() {
      return headersGenerator()
    },
  },

  methods: {
    async _fetch() {
      const filter = { site: { $ne: 'Bloomberg' } }
      if (this.search) {
        filter.title = { $regex: `.*${this.search}.*` }
      }
      const url =
        '/api/v2/public/News?isPaging=true&limit=30&sort={"createdAt":-1}' +
        `&page=${this.page}` +
        `&filter=${JSON.stringify(filter)}`
      const { docs, lastPage } = await this.$http.get(url)
      this.totalPage = lastPage
      this.news = docs
    },
  },

  watch: {
    page() {
      this._fetch()
    },
  },
}
</script>
