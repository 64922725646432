import Vue from 'vue/dist/vue.js'
import { headerItem } from '@/utils/table'
import { formatNumber, formatDate } from '@/utils/string/index.js'

export default function headers () {
  return [
    headerItem('Site', 'site', {
      cellClass: 'font-bold sticky left-0',
      generator: (item) => {
        return { value: item.site }
      },
    }),
    headerItem('Link', 'link', {
      generator: (item) => {
        return {
          component: Vue.component('a-tag', {
            props: ['href', 'text'],
            template: '<a :href="href" target="_blank">{{ text }}</a>',
          }),
          props: {
            href: item.url,
            text: item.title,
          },
        }
      },
    }),
    headerItem('BTC Price', 'price', {
      cellClass: 'font-bold',
      generator: (item) => {
        return {
          value: formatNumber(item.price),
        }
      },
    }),
    headerItem('Datetime', 'time', {
      generator: (item) => {
        return {
          value: formatDate(item.timestamp),
        }
      },
    }),
  ]
}
